// extracted by mini-css-extract-plugin
export var button = "story-block-module--button--ETivu";
export var column = "story-block-module--column--1xz2j";
export var content = "story-block-module--content--Ff3i2";
export var image = "story-block-module--image--Gnxkn";
export var imageContainer = "story-block-module--imageContainer--vF+Fk";
export var left = "story-block-module--left--Sc2vY";
export var right = "story-block-module--right--WJR6z";
export var storyBlock = "story-block-module--storyBlock--BucKt";
export var text = "story-block-module--text--bY7Kz";
export var title = "story-block-module--title--BIwdx";