import React from 'react'
import { Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { StoryQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
} from '../../components/Blanket'
import { SEO } from '../../components/Seo'
import { StoryBlock } from '../../components/StoryBlock'

import * as classes from './la-storia.module.scss'
import { Container } from '../../components/Container'

interface Props {
  data: StoryQuery
}

const getDirection = (index: number) => {
  const key = index % 2

  switch (key) {
    case 0:
      return 'right'
    case 1:
    default:
      return 'left'
  }
}

export default ({ data }: Props) => (
  <Blanket>
    <SEO title={'La Storia'} />

    <BlanketBackground>
      <BlanketTitle>La Storia</BlanketTitle>

      <Row>
        {data.allWordpressPost.nodes.map((node, index) => (
          <Col
            md={6}
            className={classNames(
              classes.column,
              classes[getDirection(index + 1)]
            )}
            key={node.id!}
          >
            <StoryBlock
              year={node.slug!}
              direction={getDirection(index)}
              link={`/la-storia/${node.slug}`}
              content={node.excerpt!}
              cover={
                node.featured_media?.localFile?.childImageSharp
                  ?.fluid as FluidObject
              }
              coverAlt={node.featured_media?.alt_text!}
            />
          </Col>
        ))}

        <Col
          md={6}
          className={classNames(classes.column, classes.right, classes.last)}
        />
      </Row>
    </BlanketBackground>
  </Blanket>
)

export const query = graphql`
  query Story {
    allWordpressPost(
      filter: { categories: { elemMatch: { wordpress_id: { eq: 43 } } } }
      sort: { fields: [slug], order: ASC }
    ) {
      nodes {
        id
        slug
        title
        excerpt
        featured_media {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1080, maxHeight: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
