import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { Button } from '../Button'
import { Media } from '../Media'
import { Section } from '../Section'

import { parse } from '../../utils/parse'

import * as classes from './story-block.module.scss'

interface Props {
  direction?: 'left' | 'right'
  content: string
  coverAlt?: string
  cover?: string | FluidObject
  link: string
  year: string
}

export const StoryBlock: React.FC<Props> = ({
  direction = 'left',
  content,
  coverAlt,
  cover,
  link,
  year,
}) => (
  <Section
    as={'div'}
    className={classes.storyBlock}
    titleClassName={classNames(classes.title, classes[direction])}
    title={year}
  >
    <div className={classes.imageContainer}>
      <Media data={cover} imageClassName={classes.image} alt={coverAlt} />

      <div className={classes.content}>
        <div className={classes.text}>{parse(content)}</div>

        <div className={classes.buttonContainer}>
          <Button as={Link} className={classes.button} to={link}>
            Leggi tutto
          </Button>
        </div>
      </div>
    </div>
  </Section>
)
